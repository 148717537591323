import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./slideShow.scss";

const fadeImages = [
  {
    url: require("../../img/hero.jpg"),
    id: 1,
  },
  {
    url: require("../../img/rouge_05.jpg"),
    id: 1,
  },
  {
    url: require("../../img/rouge_04.jpg"),
    id: 2,
  },
  {
    url: require("../../img/rouge_03.jpg"),
    id: 3,
  },
  {
    url: require("../../img/rouge_01.jpg"),
    id: 4,
  },
  {
    url: require("../../img/rouge_02.jpg"),
    id: 5,
  },
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Fade>
        {fadeImages.map((fadeImage) => (
          <div key={fadeImage.id}>
            <img
              style={{ width: "100%" }}
              src={fadeImage.url}
              alt="slideshow"
            />
            {/* <h2>{fadeImage.caption}</h2> */}
          </div>
        ))}
      </Fade>
    </div>
  );
};
export default Slideshow;
